import React, { useState } from 'react';
import ContactElement from './ContactElement';
import { Link} from 'react-router-dom';

export default function Gallery() {
    return (
        <div className='gallery'>
            <p className='gallery-links'>
                <h1 className='offer-prof'>PROFESJONALNE</h1>
                <h1 className='offer-garden'>USŁUGI OGRODNICZE</h1>
                <Link className='gallery-link' to='gardenGallery'>
                    <img className='gallery-icon' src={`${process.env.PUBLIC_URL}/galleryIconGarden.png`}></img>
                </Link>
                <h1 className='offer-prof'>PROFESJONALNE</h1>
                <h1 className='offer-wash'>MYCIE KOSTKI BRUKOWEJ</h1>
                <Link className='gallery-link' to='washGallery'>
                    <img className='gallery-icon' src={`${process.env.PUBLIC_URL}/galleryIconWash.png`}></img>
                </Link>
            </p>
            <p className='contact-box'>
                <ContactElement />
            </p>
        </div>
    );
}