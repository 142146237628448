import './App.css';
import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Contact from './components/Contact';
import About from './components/About';
import Offer from './components/Offer';
import Gallery from './components/Gallery';
import Header from './components/Header';
import Footer from './components/Footer';
import GardenGallery from './components/GardenGallery';
import WashGallery from './components/WashGallery';
import { BrowserRouter as Router } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery/gardenGallery" element={<GardenGallery />} />
          <Route path="/gallery/washGallery" element={<WashGallery />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
