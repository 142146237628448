import React from 'react';

export default function ContactElement() {
    return (
        <div className='contact-element'>
            <h1 className='contact-element-header'>KONTAKT</h1>
            <div className='contact-element-details'>
                <p className='contact-element-details-text'>☏ tel. 452 433 149</p>
                <p className='contact-element-details-text'>✉ kontakt@gardenforce.pl</p>
            </div>
        </div>
    )
}