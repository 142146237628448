import React from 'react';
import ContactElement from './ContactElement';

export default function About() {
    return (
        <div className='about'>
            <div className='about-box'>
                <h1 className='about-header'>O NAS</h1>
                <div className='about-description'>
                    <p>Garden Force - ogrodnicza firma, której jesteśmy częścią, to miejsce, gdzie pasja do zieleni i natury przenika wszystkie nasze działania. Nasz zespół składa się z doświadczonych ogrodników, projektantów krajobrazu i specjalistów ds. pielęgnacji roślin, którzy poświęcają swoje serca, aby tworzyć piękne i funkcjonalne przestrzenie.</p>
                    <p>Oferujemy szeroki zakres usług ogrodniczych, obejmujących projektowanie i zakładanie ogrodów, pielęgnację roślin, cięcie drzew, zakładanie trawników oraz dostarczanie najwyższej jakości roślin i akcesoriów ogrodniczych. Nasze podejście jest indywidualne do każdego klienta, zawsze staramy się zrozumieć ich potrzeby i preferencje, aby stworzyć wymarzone ogrody, które zachwycą swoją urodą i harmonią.</p>
                    <p>Jeśli szukasz profesjonalnej i zaangażowanej firmy ogrodniczej, która przekształci Twoją przestrzeń w magiczny ogród, zapraszamy do skorzystania z naszych usług. Daj nam szansę, a z pewnością nie będziesz rozczarowany rezultatami naszej pracy i troskliwym podejściem do Twojego ogrodu.</p>
                </div>
            </div>
            <div className='contact-box'>
                <ContactElement />
            </div>
        </div>
    )
}