import React, { useState } from 'react';
import ContactElement from './ContactElement';

const images = require.context('../../public/photos/garden', false, /\.(jpg|jpeg|png)$/);
const imageFileNames = images.keys();

const IMAGES_PER_PAGE = 12;

export default function GardenGallery() {
    const [currentPage, setCurrentPage] = useState(1);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const startIndex = (currentPage - 1) * IMAGES_PER_PAGE;
    const endIndex = startIndex + IMAGES_PER_PAGE;
    const displayedImages = imageFileNames.slice(startIndex, endIndex);

    return (
        <div className='gallery-garden'>
            <h1 className='gallery-header'>
                GALERIA
            </h1>
            <div className='gallery-box'>
                {displayedImages.map((fileName, index) => (
                    <img
                        className='gallery-img'
                        key={index}
                        src={`${process.env.PUBLIC_URL}/photos/garden${fileName.slice(1)}`}
                        alt={fileName}
                    />
                ))}
            </div>
            <div className='pagination'>
                <button className='pagination-button' onClick={handlePrevPage} disabled={currentPage === 1}>←</button>
                <button className='pagination-button' onClick={handleNextPage} disabled={endIndex >= imageFileNames.length}>→</button>
            </div>
        </div>
    );
}