import React from 'react';
import ContactElement from './ContactElement';

export default function Offer() {
    return (
        <div className='offer'>
            <div className='offer-box'>
                <h1 className='offer-prof'>PROFESJONALNE</h1>
                <h1 className='offer-garden'>USŁUGI OGRODNICZE</h1>
                <img className='offer-img' src={`${process.env.PUBLIC_URL}/prostokatOgrodnicze-01.png`} alt="offer" />
                <h1 className='offer-prof'>PROFESJONALNE</h1>
                <h1 className='offer-wash'>MYCIE KOSTKI BRUKOWEJ</h1>
                <img className='offer-img' src={`${process.env.PUBLIC_URL}/prostokatMycie-01.png`} alt="offer" />
            </div>
            <div className='contact-box'>
                <ContactElement />
            </div>
        </div>
    )
}