import React from 'react';
import { Link} from 'react-router-dom';

export default function Navbar() {
   return (
         <div className='navbar'>
            <Link className='navbar-link' to='/'>O NAS</Link>
            <Link className='navbar-link' to='gallery'>GALERIA</Link>
            <Link className='navbar-link' to='offer'>OFERTA</Link>
            <Link className='navbar-link' to='contact'>KONTAKT</Link>
        </div>
    )
}