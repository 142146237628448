import React from 'react';
import Navbar from './Navbar';

export default function Header() {
    return (
        <div className='header'>
            <img className='header-img' src={`${process.env.PUBLIC_URL}/top-01.png`} alt="header" />
            <Navbar className='header-navbar'/>
        </div>
    )
}